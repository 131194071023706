function CenterPiece() {
  return (
      <div>
          <div className="container">
              <div className="row">
                  <div className="col ">
                      <h1 className="text-center">Hey, I'm Aondre</h1>
                      <h3 className="text-center">Software Engineer | 3D Enthusiast | Lifelong Learner</h3>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default CenterPiece;
