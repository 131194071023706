import PassObj from "../components/posts";

function Blog() {
  return (
      <div>
          <PassObj />
      </div>
  )
}

export default Blog;
