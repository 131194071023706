import CenterPiece from "../components/centerPiece";

function Home() {
  return (
      <div className="d-flex align-items-center justify-content-center" style={{height: "80%"}}>
          <CenterPiece />
      </div>
  )
}

export default Home;
